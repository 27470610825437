import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, IconButton, Sheet, Typography, useColorScheme } from "@mui/joy";
import { useState } from "react";
import { letters } from "../../data/novellaData";

interface LetterDisplayProps {
  letterId: string;
}

const LetterDisplay = ({ letterId }: LetterDisplayProps) => {
  const [expanded, setExpanded] = useState(true);
  const { mode } = useColorScheme();
  const isDark = mode === "dark";

  const letter = letters[letterId];

  if (!letter) return null;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Sheet
      variant="outlined"
      sx={{
        my: 4,
        mx: "auto",
        maxWidth: "90%",
        position: "relative",
        borderRadius: "8px",
        bgcolor: isDark
          ? "rgba(255, 246, 225, 0.03)"
          : "rgba(255, 246, 225, 0.6)",
        boxShadow: "md",
        overflow: "hidden",
        transition: "all 0.3s ease",
        borderColor: isDark
          ? "rgba(255, 211, 145, 0.2)"
          : "rgba(165, 113, 0, 0.3)",
        transform: expanded ? "none" : "scale(0.97)",
        height: expanded ? "auto" : "120px",
      }}
    >
      {/* Scroll top edge */}
      <Box
        sx={{
          height: "24px",
          bgcolor: isDark
            ? "rgba(205, 162, 96, 0.2)"
            : "rgba(205, 162, 96, 0.3)",
          borderBottom: "1px solid",
          borderColor: isDark
            ? "rgba(205, 162, 96, 0.3)"
            : "rgba(165, 113, 0, 0.4)",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "8px",
            bottom: 0,
            backgroundImage:
              "linear-gradient(to right, transparent, rgba(255, 246, 225, 0.2) 15%, rgba(255, 246, 225, 0.2) 85%, transparent)",
          }}
        />
      </Box>

      {/* Letter content */}
      <Box
        sx={{
          p: { xs: 3, md: 4 },
          pt: 2,
          maxHeight: expanded ? "800px" : "80px",
          overflow: expanded ? "auto" : "hidden",
          transition: "max-height 0.5s ease",
          backgroundImage: isDark
            ? "radial-gradient(circle at center, rgba(255, 246, 225, 0.03) 0%, rgba(255, 246, 225, 0) 70%)"
            : "radial-gradient(circle at center, rgba(255, 246, 225, 0.5) 0%, rgba(255, 246, 225, 0.2) 70%)",
          backgroundSize: "200% 200%",
          backgroundPosition: "center center",
          position: "relative",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "url(/assets/paper-texture.png)",
            opacity: isDark ? 0.05 : 0.1,
            pointerEvents: "none",
            mixBlendMode: "overlay",
          },
        }}
      >
        <Typography
          level="title-lg"
          sx={{
            fontFamily: "'Cinzel', serif",
            mb: 3,
            color: isDark ? "warning.300" : "warning.700",
            textAlign: "center",
            fontWeight: "bold",
            textShadow: isDark ? "0 0 1px rgba(255,211,145,0.3)" : "none",
            position: "relative",
            "&::after": {
              content: '""',
              display: "block",
              width: "40%",
              height: "1px",
              backgroundColor: isDark ? "warning.400" : "warning.600",
              margin: "8px auto 0",
              opacity: 0.6,
            },
          }}
        >
          {letter.title}
        </Typography>

        <Typography
          level="body-md"
          sx={{
            fontFamily: "'EB Garamond', serif",
            whiteSpace: "pre-wrap",
            color: isDark ? "neutral.100" : "neutral.800",
            fontSize: { xs: "1rem", md: "1.1rem" },
            lineHeight: 1.8,
            letterSpacing: "0.01em",
            "& strong": {
              color: isDark ? "warning.200" : "warning.800",
            },
            position: "relative",
            zIndex: 2,
          }}
        >
          {letter.content}
        </Typography>
      </Box>

      {/* Scroll bottom edge */}
      <Box
        sx={{
          height: "24px",
          bgcolor: isDark
            ? "rgba(205, 162, 96, 0.2)"
            : "rgba(205, 162, 96, 0.3)",
          borderTop: "1px solid",
          borderColor: isDark
            ? "rgba(205, 162, 96, 0.3)"
            : "rgba(165, 113, 0, 0.4)",
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "8px",
            top: 0,
            backgroundImage:
              "linear-gradient(to right, transparent, rgba(255, 246, 225, 0.2) 15%, rgba(255, 246, 225, 0.2) 85%, transparent)",
          }}
        />
      </Box>

      {/* Expand/Collapse button */}
      <IconButton
        onClick={toggleExpanded}
        size="sm"
        variant="outlined"
        color="neutral"
        sx={{
          position: "absolute",
          bottom: expanded ? 30 : 10,
          right: 10,
          backgroundColor: isDark
            ? "rgba(0, 0, 0, 0.4)"
            : "rgba(255, 255, 255, 0.7)",
          border: "1px solid",
          borderColor: isDark
            ? "rgba(255, 255, 255, 0.2)"
            : "rgba(0, 0, 0, 0.1)",
          "&:hover": {
            backgroundColor: isDark
              ? "rgba(0, 0, 0, 0.5)"
              : "rgba(255, 255, 255, 0.9)",
          },
        }}
      >
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Sheet>
  );
};

export default LetterDisplay;
