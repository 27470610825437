import { Box, useColorScheme } from "@mui/joy";
import { useEffect, useState } from "react";

const CandleEffect = () => {
  const [flickerIntensity, setFlickerIntensity] = useState(1);
  const [flameHeight, setFlameHeight] = useState(1);
  const { mode } = useColorScheme();
  const isDark = mode === "dark";

  useEffect(() => {
    // Simulate candle flickering
    const flickerInterval = setInterval(() => {
      const intensity = 0.7 + Math.random() * 0.6; // Value between 0.7 and 1.3
      setFlickerIntensity(intensity);

      // Occasionally change flame height
      if (Math.random() > 0.7) {
        const height = 0.8 + Math.random() * 0.4; // Value between 0.8 and 1.2
        setFlameHeight(height);
      }
    }, 100);

    return () => clearInterval(flickerInterval);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: { xs: 40, md: 60 },
        height: { xs: 80, md: 120 },
        mb: -2,
      }}
    >
      {/* Candle body */}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: { xs: 20, md: 30 },
          height: { xs: 40, md: 60 },
          bgcolor: "#f5f5dc", // Off-white candle color
          borderRadius: "3px",
          boxShadow: isDark ? "0 0 8px rgba(255, 255, 255, 0.3)" : "none",
        }}
      />

      {/* Candle wick - always visible */}
      <Box
        sx={{
          position: "absolute",
          bottom: { xs: 40, md: 60 },
          left: "50%",
          transform: "translateX(-50%)",
          width: { xs: 2, md: 3 },
          height: { xs: 4, md: 6 },
          bgcolor: "#333",
          borderRadius: "1px",
          zIndex: 2,
        }}
      />

      {/* Flame elements - only in dark mode */}
      {isDark && (
        <>
          {/* Flame outer glow */}
          <Box
            sx={{
              position: "absolute",
              bottom: { xs: 40, md: 60 },
              left: "50%",
              transform: "translateX(-50%)",
              width: { xs: 16, md: 24 },
              height: { xs: 24 * flameHeight, md: 36 * flameHeight },
              borderRadius: "50% 50% 50% 50% / 60% 60% 40% 40%",
              background: `radial-gradient(ellipse at center, rgba(255, 200, 0, ${
                0.5 * flickerIntensity
              }) 0%, rgba(255, 150, 0, ${
                0.2 * flickerIntensity
              }) 60%, rgba(255, 100, 0, 0) 100%)`,
              filter: `blur(5px)`,
              opacity: flickerIntensity,
              transition: "height 0.1s ease-out",
              zIndex: 1,
            }}
          />

          {/* Flame inner */}
          <Box
            sx={{
              position: "absolute",
              bottom: { xs: 40, md: 60 },
              left: "50%",
              transform: "translateX(-50%)",
              width: { xs: 8, md: 12 },
              height: { xs: 16 * flameHeight, md: 24 * flameHeight },
              borderRadius: "50% 50% 50% 50% / 60% 60% 40% 40%",
              background: `linear-gradient(to top, #ff7800 0%, #ffd700 ${
                60 * flickerIntensity
              }%, #ffac00 100%)`,
              boxShadow: `0 0 ${10 * flickerIntensity}px ${
                6 * flickerIntensity
              }px rgba(255, 160, 0, 0.6)`,
              opacity: flickerIntensity,
              transition: "height 0.1s ease-out",
              zIndex: 1,
            }}
          />
        </>
      )}

      {/* Candle light on the table */}
      {isDark && (
        <Box
          sx={{
            position: "absolute",
            bottom: -10,
            left: "50%",
            transform: "translateX(-50%)",
            width: { xs: 80, md: 120 },
            height: { xs: 40, md: 60 },
            borderRadius: "50%",
            background: `radial-gradient(ellipse at center, rgba(255, 200, 0, ${
              0.3 * flickerIntensity
            }) 0%, rgba(255, 150, 0, ${
              0.1 * flickerIntensity
            }) 60%, rgba(255, 100, 0, 0) 100%)`,
            filter: "blur(8px)",
            opacity: 0.5 * flickerIntensity,
          }}
        />
      )}
    </Box>
  );
};

export default CandleEffect;
