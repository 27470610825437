import HomeIcon from "@mui/icons-material/Home";
import { Box, Button, Tooltip, Typography } from "@mui/joy";
import { onValue, ref, runTransaction } from "firebase/database";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { database } from "../../utils/firebase";
import ModeToggle from "../ModeToggle";
import CandleEffect from "./CandleEffect";

const ReaderHeader = () => {
  const [viewCount, setViewCount] = useState<number | null>(null);

  useEffect(() => {
    // Get Firebase Database reference
    const viewsRef = ref(database, "ror-read-online");

    // Check if this is a unique visitor this month
    const visitStorageKey = "timelessaria_ror_visited";
    const currentMonth = `${new Date().getMonth()}_${new Date().getFullYear()}`;
    const previousVisit = localStorage.getItem(visitStorageKey);
    const isNewVisit = previousVisit !== currentMonth;

    // If this is a new visit this month, increment the count
    if (isNewVisit) {
      // Store current month in localStorage
      localStorage.setItem(visitStorageKey, currentMonth);

      // Increment the visitor count
      runTransaction(viewsRef, (currentValue) => {
        return (currentValue || 0) + 1;
      }).catch((error) => {
        console.error("Error incrementing read count:", error);
      });
    }

    // Listen for count updates
    const unsubscribe = onValue(
      viewsRef,
      (snapshot) => {
        setViewCount(snapshot.val() || 0);
      },
      (error) => {
        console.error("Firebase error:", error);
        setViewCount(0); // Default to 0 if there's an error
      }
    );

    // Clean up listener
    return () => unsubscribe();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        py: 2,
        mb: 4,
        borderBottom: "1px solid",
        borderColor: "divider",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <CandleEffect />
          <Typography
            level="h2"
            sx={{
              fontFamily: "'Cinzel', serif",
              fontSize: { xs: "24px", sm: "36px", md: "48px" },
            }}
          >
            Reports of Rebellion
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", ml: 10, mt: 1, alignItems: "center", gap: 3 }}
        >
          <Typography
            level="body-sm"
            sx={{ fontStyle: "italic", color: "neutral.500" }}
          >
            {viewCount !== null
              ? `${viewCount} ${viewCount === 1 ? "reader" : "readers"} to date`
              : "Loading..."}
          </Typography>
          <Tooltip title="Return to homepage" placement="right">
            <Button
              component={Link}
              to="/"
              variant="outlined"
              color="neutral"
              size="sm"
              startDecorator={<HomeIcon />}
              sx={{ borderRadius: "md" }}
            >
              Home
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ position: "absolute", right: 0, top: 0, m: 2 }}>
        <ModeToggle />
      </Box>
    </Box>
  );
};

export default ReaderHeader;
