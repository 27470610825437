import ArticleIcon from "@mui/icons-material/Article";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailIcon from "@mui/icons-material/Mail";
import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  Sheet,
  Typography,
  useColorScheme,
} from "@mui/joy";
import { letters } from "../../data/novellaData";
import { Chapter } from "../../types/novellaTypes";
import {
  chapterToLetterMapping,
  getReadLetters,
  isChapterCompleted,
  resetReadingProgress,
} from "../../utils/cookieUtils";

interface ChapterNavigationProps {
  chapters: Chapter[];
  currentChapter: Chapter;
  onChapterChange: (chapter: Chapter) => void;
  readLetters: string[];
}

const ChapterNavigation = ({
  chapters,
  currentChapter,
  onChapterChange,
  readLetters,
}: ChapterNavigationProps) => {
  const { mode } = useColorScheme();
  const isDark = mode === "dark";

  return (
    <Sheet
      variant="outlined"
      sx={{
        borderRadius: "sm",
        p: 1,
        position: "sticky",
        top: 16,
        maxHeight: "calc(100vh - 32px)",
        overflowY: "auto",
        bgcolor: "background.surface",
        boxShadow: isDark ? "sm" : "none",
      }}
    >
      {/* Chapters Section */}
      <Box sx={{ mb: 3 }}>
        <Typography
          level="title-lg"
          sx={{
            mb: 2,
            px: 2,
            pt: 1,
            fontFamily: "'Cinzel', serif",
          }}
        >
          Chapters
        </Typography>
        <List size="sm" sx={{ "--ListItem-radius": "8px" }}>
          {chapters
            .filter(
              (chapter) => !chapter.containsLetter && chapter.id !== "letters"
            )
            .map((chapter) => (
              <ListItem key={chapter.id}>
                <ListItemButton
                  selected={chapter.id === currentChapter.id}
                  onClick={() => onChapterChange(chapter)}
                  sx={{
                    border: chapter.id === currentChapter.id ? 1 : 0,
                    borderColor: "divider",
                    borderRadius: "sm",
                  }}
                >
                  <ListItemContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        level="body-sm"
                        sx={{
                          fontFamily: "'Cinzel', serif",
                          fontWeight:
                            chapter.id === currentChapter.id
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {chapter.title}
                      </Typography>

                      {isChapterCompleted(chapter.id) ? (
                        <Box
                          sx={{
                            position: "relative",
                            "&:hover .cancel-icon": {
                              display: "block",
                            },
                          }}
                        >
                          <CheckCircleIcon
                            fontSize="small"
                            sx={{
                              ml: 1,
                              color: "warning.500",
                              opacity: 0.9,
                            }}
                          />
                          <CancelIcon
                            className="cancel-icon"
                            onClick={(e) => {
                              e.stopPropagation();
                              // Remove from completed list
                              const completedChapters = document.cookie
                                .split("; ")
                                .find((row) =>
                                  row.startsWith("completedChapters=")
                                );

                              if (completedChapters) {
                                let chaptersArray = JSON.parse(
                                  decodeURIComponent(
                                    completedChapters.split("=")[1]
                                  )
                                );
                                chaptersArray = chaptersArray.filter(
                                  (id: string) => id !== chapter.id
                                );
                                document.cookie = `completedChapters=${JSON.stringify(
                                  chaptersArray
                                )};path=/;max-age=31536000`;
                                // Force rerender
                                window.location.reload();
                              }
                            }}
                            sx={{
                              position: "absolute",
                              top: -5,
                              right: -5,
                              ml: 1,
                              color: "error.500",
                              fontSize: "14px",
                              display: "none",
                              cursor: "pointer",
                              backgroundColor: isDark
                                ? "rgba(0,0,0,0.4)"
                                : "rgba(255,255,255,0.8)",
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                      ) : (
                        <ArticleIcon
                          fontSize="small"
                          sx={{
                            ml: 1,
                            color: isDark ? "neutral.400" : "neutral.600",
                            opacity: 0.7,
                          }}
                        />
                      )}
                    </Box>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
        </List>

        {/* Reset Progress Button */}
        <Box sx={{ mt: 3, mb: 1, textAlign: "center" }}>
          <Box
            onClick={resetReadingProgress}
            sx={{
              py: 1.5,
              px: 2,
              mx: "auto",
              display: "inline-block",
              borderRadius: "md",
              cursor: "pointer",
              fontFamily: "'Cinzel', serif",
              fontSize: "0.85rem",
              fontWeight: "medium",
              border: "1px solid",
              borderColor: isDark ? "neutral.700" : "neutral.300",
              color: isDark ? "neutral.400" : "neutral.600",
              bgcolor: isDark ? "neutral.900" : "background.body",
              transition: "all 0.2s",
              "&:hover": {
                bgcolor: isDark ? "error.900" : "error.100",
                color: isDark ? "error.200" : "error.700",
                borderColor: isDark ? "error.700" : "error.300",
              },
            }}
          >
            Reset Reading Progress
          </Box>
        </Box>
      </Box>

      {/* Letters Section - Only visible when letters are unlocked */}
      {getReadLetters().length > 0 && (
        <Box sx={{ mt: 3 }}>
          <Typography
            level="title-lg"
            sx={{
              mb: 2,
              px: 2,
              pt: 1,
              fontFamily: "'Cinzel', serif",
              color: isDark ? "warning.300" : "warning.600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            Letters
            <Chip
              size="sm"
              variant="outlined"
              color="warning"
              sx={{
                fontFamily: "'EB Garamond', serif",
                cursor: "pointer",
                "&:hover": {
                  bgcolor: isDark ? "warning.800" : "warning.100",
                },
              }}
              startDecorator={<MailIcon fontSize="small" />}
              onClick={() => {
                const lettersChapter = chapters.find(
                  (chapter) => chapter.id === "letters"
                );
                if (lettersChapter) {
                  // Clear any existing hash first
                  if (window.location.hash) {
                    window.history.pushState(
                      "",
                      document.title,
                      window.location.pathname
                    );
                  }
                  onChapterChange(lettersChapter);
                }
              }}
            >
              {getReadLetters().length}
            </Chip>
          </Typography>
          <List size="sm" sx={{ "--ListItem-radius": "8px" }}>
            {/* Show individual letter links */}
            {Object.entries(chapterToLetterMapping)
              .filter(([chapterId, letterId]) =>
                getReadLetters().includes(letterId)
              )
              .map(([chapterId, letterId]) => {
                // Get the letter title from letters data
                const letterTitle = letters[letterId]?.title;

                if (!letterTitle) return null;

                const isSelected =
                  currentChapter.id === "letters" &&
                  window.location.hash === `#${letterId}`;

                return (
                  <ListItem key={letterId}>
                    <ListItemButton
                      selected={isSelected}
                      onClick={() => {
                        const lettersChapter = chapters.find(
                          (chapter) => chapter.id === "letters"
                        );
                        if (lettersChapter) {
                          // Set hash to scroll to specific letter
                          window.location.hash = letterId;
                          onChapterChange(lettersChapter);
                        }
                      }}
                      sx={{
                        border: isSelected ? 1 : 0,
                        borderColor: "divider",
                        borderRadius: "sm",
                        bgcolor: isSelected
                          ? isDark
                            ? "warning.900"
                            : "warning.100"
                          : "transparent",
                      }}
                    >
                      <ListItemContent>
                        <Typography
                          level="body-sm"
                          sx={{
                            fontFamily: "'Cinzel', serif",
                            fontWeight: isSelected ? "bold" : "normal",
                            color: isDark ? "warning.300" : "warning.600",
                          }}
                        >
                          {letterTitle}
                        </Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                );
              })}
          </List>
        </Box>
      )}
    </Sheet>
  );
};

export default ChapterNavigation;
