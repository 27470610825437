import { CssVarsProvider, StyledEngineProvider } from "@mui/joy/styles";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import MapPage from "./components/MapPage/MapPage";
import NovellaReaderWrapper from "./components/NovellaReader/NovellaReaderWrapper";
import SapphireSaturdays from "./SapphireSaturdays";
import theme from "./theme";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/sapphiresaturdays",
    element: <SapphireSaturdays />,
  },
  {
    path: "/novella",
    element: <NovellaReaderWrapper />,
  },
  {
    path: "/maps",
    element: <MapPage />,
  },
]);

ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssVarsProvider defaultMode="dark" disableNestedContext theme={theme}>
        <RouterProvider router={router} />
      </CssVarsProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
