import MapIcon from "@mui/icons-material/Map";
import {
  Avatar,
  Box,
  Button,
  Modal,
  ModalClose,
  Sheet,
  Typography,
  useColorScheme,
} from "@mui/joy";
import { useState } from "react";
import { locations } from "../../data/novellaData";

interface LocationNameProps {
  id: string;
  children: React.ReactNode;
}

const LocationName = ({ id, children }: LocationNameProps) => {
  const [open, setOpen] = useState(false);
  const { mode } = useColorScheme();
  const isDark = mode === "dark";

  const location = locations[id];

  if (!location) {
    return <>{children}</>;
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleViewOnMap = () => {
    setOpen(false);
    // Open the map page with this location selected
    let url = "/maps";
    if (location.mapCoordinates) {
      url = `/maps?location=${id}`;
    }
    window.open(url, "_blank");
  };

  return (
    <>
      <Typography
        component="span"
        onClick={handleClick}
        sx={{
          color: isDark ? "primary.300" : "primary.600",
          cursor: "pointer",
          fontWeight: "500",
          textDecoration: "none",
          position: "relative",
          "&:after": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: 0,
            backgroundColor: isDark ? "primary.300" : "primary.600",
            opacity: 0,
            transition: "opacity 0.2s",
          },
          "&:hover": {
            color: isDark ? "primary.200" : "primary.700",
            "&:after": {
              opacity: 0.5,
            },
          },
        }}
      >
        {children}
      </Typography>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 430,
            borderRadius: "lg",
            p: 4,
            boxShadow: "lg",
            position: "relative",
            border: "1px solid",
            borderColor: isDark ? "neutral.700" : "neutral.300",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "4px",
              bgcolor: isDark ? "primary.600" : "primary.500",
            },
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ position: "relative", mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 3,
                  mb: 4,
                }}
              >
                <Avatar
                  src={location.image}
                  alt={location.name}
                  sx={{
                    width: 100,
                    height: 100,
                    border: "3px solid",
                    borderColor: isDark ? "primary.600" : "primary.400",
                    boxShadow: "sm",
                    bgcolor: isDark ? "primary.900" : "primary.50",
                  }}
                >
                  {location.name.charAt(0)}
                </Avatar>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    level="title-lg"
                    sx={{
                      fontFamily: "'Cinzel', serif",
                      fontWeight: "bold",
                      mb: 1.5,
                      lineHeight: 1.2,
                      display: "block",
                      color: isDark ? "primary.200" : "primary.700",
                    }}
                  >
                    {location.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              level="body-md"
              sx={{
                fontFamily: "'EB Garamond', serif",
                lineHeight: 1.6,
                letterSpacing: "0.015em",
                mb: location.mapCoordinates ? 3 : 0,
              }}
            >
              {location.description}
            </Typography>

            {location.mapCoordinates && (
              <Button
                startDecorator={<MapIcon />}
                onClick={handleViewOnMap}
                variant="soft"
                color="primary"
                size="lg"
                sx={{
                  mt: 2,
                  alignSelf: "flex-end",
                  fontFamily: "'Cinzel', serif",
                  fontSize: "0.9rem",
                  fontWeight: "500",
                }}
              >
                View on Map
              </Button>
            )}
          </Box>
        </Sheet>
      </Modal>
    </>
  );
};

export default LocationName;
