import { Box, Option, Select, Stack, Typography, useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";
import L from "leaflet";
import "leaflet-rastercoords";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MapLegend from "../../assets/MapLegend.png";
import { locations } from "../../data/novellaData";
import ModeToggle from "../ModeToggle";

const MAP_BOOKS = [
  { id: "book1", name: "Book 1: The Sapphire Shore - 499 F.O." },
  { id: "book2", name: "Book 2 (Coming Soon)" },
  { id: "book3", name: "Book 3 (Coming Soon)" },
];

interface MapLocation {
  id: string;
  name: string;
  coordinates: { x: number; y: number };
}

// Main Map Component
const MapPage = () => {
  const mapRef = useRef<null | HTMLDivElement>(null);
  const mapInstance = useRef<L.Map | null>(null);
  const rasterCoords = useRef<any>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBook, setSelectedBook] = useState("book1");
  const [mapLocations, setMapLocations] = useState<MapLocation[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string>("");

  // Format locations from data for the dropdown
  useEffect(() => {
    const formattedLocations = Object.entries(locations)
      .filter(([_, location]) => location.mapCoordinates)
      .map(([id, location]) => ({
        id,
        name: location.name,
        coordinates: location.mapCoordinates || { x: 4500, y: 4000 },
      }));

    setMapLocations(formattedLocations);
  }, []);

  // Initialize the map
  useEffect(() => {
    if (mapRef.current && !mapInstance.current) {
      // Create Leaflet map instance
      const map = L.map(mapRef.current, {
        crs: L.CRS.Simple,
        minZoom: 2,
        maxZoom: 7,
      });
      mapInstance.current = map;

      // Set up map dimensions
      const img = { width: 9728, height: 9728 };
      const rc = new L.RasterCoords(map, [img.width, img.height]);
      rasterCoords.current = rc;

      // Default view
      map.setView(rc.unproject([img.width / 2 + 200, img.height / 2 - 400]), 3);
      map.attributionControl.setPrefix("");

      // Add tile layer
      L.tileLayer("/map/tiles/{z}/{x}/{y}.png", {
        noWrap: true,
        attribution: "",
        bounds: rc.getMaxBounds(),
        maxNativeZoom: rc.zoomLevel(),
      }).addTo(map);

      // Check if we have location parameters
      const locationParam = searchParams.get("location");
      if (locationParam) {
        setSelectedLocation(locationParam);
        const location = mapLocations.find((loc) => loc.id === locationParam);
        if (location && rasterCoords.current) {
          const point = rasterCoords.current.unproject([
            location.coordinates.x,
            location.coordinates.y,
          ]);
          map.setView(point, 5);
        }
      }

      // Add markers for locations
      mapLocations.forEach((locationInfo) => {
        if (locationInfo.coordinates) {
          // Get full location data with realm info
          const fullLocation = locations[locationInfo.id];
          if (!fullLocation) return;

          // Set marker color based on realm
          let markerColor = "#555555"; // Default gray
          if (fullLocation.realm) {
            switch (fullLocation.realm) {
              case "Hydaria":
                markerColor = "#cc0000";
                break; // Red
              case "Rigata":
                markerColor = "#00cc00";
                break; // Green
              case "Migora":
                markerColor = "#0066cc";
                break; // Blue
              case "Vera":
                markerColor = "#9933cc";
                break; // Purple
              case "Motra":
                markerColor = "#cc8800";
                break; // Orange
            }
          }

          // Create custom marker style
          const markerHtmlStyles = `
            background-color: ${markerColor};
            width: 24px;
            height: 24px;
            display: block;
            position: relative;
            border-radius: 50% 50% 50% 0;
            transform: rotate(-45deg);
            border: 2px solid #FFFFFF;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
          `;

          const customIcon = L.divIcon({
            className: "custom-pin",
            iconAnchor: [12, 24],
            popupAnchor: [0, -24],
            tooltipAnchor: [12, -12],
            html: `<span style="${markerHtmlStyles}" />`,
          });

          const marker = L.marker(
            rc.unproject([
              locationInfo.coordinates.x,
              locationInfo.coordinates.y,
            ]),
            {
              icon: customIcon,
              title: locationInfo.name,
              riseOnHover: true,
            }
          );

          marker.addTo(map);
          marker.bindTooltip(locationInfo.name);

          // Handle marker click
          marker.on("click", () => {
            setSelectedLocation(locationInfo.id);
            const params = new URLSearchParams(searchParams);
            params.set("location", locationInfo.id);
            setSearchParams(params);
          });
        }
      });
    }

    return () => {
      if (mapInstance.current) {
        mapInstance.current.remove();
        mapInstance.current = null;
      }
    };
  }, [mapLocations, searchParams, setSearchParams]); // Only run on initial load or when locations change

  // Update map when selected location changes
  useEffect(() => {
    const location = mapLocations.find((loc) => loc.id === selectedLocation);
    if (location && mapInstance.current && rasterCoords.current) {
      const point = rasterCoords.current.unproject([
        location.coordinates.x,
        location.coordinates.y,
      ]);
      mapInstance.current.setView(point, 5);
    }
  }, [mapLocations, selectedLocation]);

  // Handle book selection change
  const handleBookChange = (value: string | null) => {
    if (value) {
      setSelectedBook(value);
      const params = new URLSearchParams(searchParams);
      params.set("book", value);
      setSearchParams(params);
    }
  };

  // Handle location selection change
  const handleLocationChange = (value: string | null) => {
    if (value) {
      setSelectedLocation(value);
      const params = new URLSearchParams(searchParams);
      params.set("location", value);
      setSearchParams(params);
    }
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* Header with controls */}
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "stretch", md: "center" },
          justifyContent: "space-between",
          gap: 2,
          borderBottom: "1px solid",
          borderColor: "divider",
          bgcolor: "background.surface",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography level="h3" sx={{ fontFamily: "'Cinzel', serif" }}>
            Timeless Aria - World Map
          </Typography>
          <ModeToggle />
        </Box>

        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          sx={{
            flexGrow: 1,
            maxWidth: { md: "600px" },
            flexWrap: "wrap",
          }}
        >
          <Select
            value={selectedBook}
            onChange={(_, value) => handleBookChange(value)}
            sx={{ minWidth: 200 }}
          >
            {MAP_BOOKS.map((book) => (
              <Option key={book.id} value={book.id}>
                {book.name}
              </Option>
            ))}
          </Select>

          <Select
            placeholder="Select a location..."
            value={selectedLocation}
            onChange={(_, value) => handleLocationChange(value)}
            sx={{ minWidth: 200 }}
          >
            {mapLocations.map((location) => (
              <Option key={location.id} value={location.id}>
                {location.name}
              </Option>
            ))}
          </Select>
        </Stack>
      </Box>

      {/* Map title and attribution */}
      <Box
        sx={{
          py: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "background.level1",
        }}
      >
        <Typography textAlign="center" level="title-lg">
          Map of Osgara, 499 F.O.
        </Typography>
        <Typography textAlign="center" level="body-sm">
          Thank you Antonio Purrmann!
        </Typography>
        <Typography textAlign="center" sx={{ mb: 1 }} level="body-sm">
          For demystifying how to get this map working in this tech stack! ❤️
        </Typography>
      </Box>

      {/* Main map container */}
      <Box sx={{ position: "relative", flexGrow: 1 }}>
        {/* Map legend */}
        {!isMobile && (
          <Box sx={{ position: "absolute", top: 10, right: 10, zIndex: 1000 }}>
            <img src={MapLegend} style={{ width: "250px" }} alt="Map Legend" />
          </Box>
        )}

        {/* The actual map */}
        <div
          ref={mapRef}
          style={{ width: "100%", height: "100%", background: "#DDCFC3" }}
        ></div>
      </Box>
    </Box>
  );
};

export default MapPage;
