import { Book, BookOnline, MenuBook, PictureAsPdf } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/joy";
import { ref, runTransaction } from "firebase/database";
import ReportsElement from "../assets/Reports/Reports Of Rebellion Web.webp";
import { database } from "../utils/firebase";

export default function PreludeSection() {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography level="title-lg" component="a" id="reportsOfRebellion">
        REPORTS OF REBELLION
      </Typography>
      <Typography level="body-xs" sx={{ mb: 2 }}>
        Copyright © 2023 Mostafa Elbehery & Aly Said, All rights reserved.
      </Typography>
      <img
        src={ReportsElement}
        width={"550px"}
        alt="Reports of Rebellion Cover"
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 4,
            width: "100%",
          }}
        >
          <Button
            aria-label="Download PDF"
            sx={{ width: "49%", mr: "1%" }}
            component="a"
            href="/assets/Timeless' Aria - Reports of Rebellion.pdf"
            download
            onClick={() => {
              runTransaction(ref(database, "ror-pdf"), (currentValue) => {
                return (currentValue || 0) + 1;
              });
            }}
            startDecorator={<PictureAsPdf />}
          >
            Download PDF
          </Button>
          <Button
            aria-label="Download EPUB"
            sx={{ width: "49%", ml: "1%" }}
            component="a"
            href="/assets/Timeless' Aria - Reports of Rebellion.epub"
            download
            onClick={() => {
              runTransaction(ref(database, "ror-epub"), (currentValue) => {
                return (currentValue || 0) + 1;
              });
            }}
            startDecorator={<BookOnline />}
          >
            Download EPUB
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            mt: 2,
          }}
        >
          <Button
            aria-label="Paperback Version on Amazon"
            sx={{ width: "49%", mr: "1%" }}
            component="a"
            href="https://www.amazon.co.uk/gp/product/3911111010"
            startDecorator={<Book />}
            color="warning"
          >
            Paperback Version on Amazon
          </Button>
          <Button
            aria-label="Read Online"
            sx={{ width: "49%", ml: "1%" }}
            component="a"
            href="/novella"
            color="success"
            variant="soft"
            onClick={() => {
              runTransaction(ref(database, "ror-reader"), (currentValue) => {
                return (currentValue || 0) + 1;
              });
            }}
            startDecorator={<MenuBook />}
          >
            Read Online with Interactive Features
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
