// Cookie utilities for storing user's reading progress

// Map of chapter IDs to their corresponding letter IDs
export const chapterToLetterMapping: Record<string, string> = {
  spark: "letter1", // Spark → Upheavals in the North
  smoke: "letter2", // Smoke → Deleterious Tidings
  flame: "letter3", // Flame → Sapphire Ire
  fire: "letter4", // Fire → Slumbering Giant
};

/**
 * Set a cookie with the given name, value and expiration days
 */
export const setCookie = (name: string, value: string, days = 365) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

/**
 * Get a cookie by name
 */
export const getCookie = (name: string): string => {
  const cookieName = `${name}=`;
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return "";
};

/**
 * Manage read letters in cookies
 */
export const getReadLetters = (): string[] => {
  const letters = getCookie("readLetters");
  return letters ? JSON.parse(letters) : [];
};

export const markLetterAsRead = (letterId: string) => {
  const readLetters = getReadLetters();
  if (!readLetters.includes(letterId)) {
    readLetters.push(letterId);
    setCookie("readLetters", JSON.stringify(readLetters));
  }
};

/**
 * Manage completed chapters in cookies
 */
export const getCompletedChapters = (): string[] => {
  const chapters = getCookie("completedChapters");
  return chapters ? JSON.parse(chapters) : [];
};

export const markChapterAsCompleted = (chapterId: string) => {
  const completedChapters = getCompletedChapters();
  if (!completedChapters.includes(chapterId)) {
    completedChapters.push(chapterId);
    setCookie("completedChapters", JSON.stringify(completedChapters));
  }
};

export const isChapterCompleted = (chapterId: string): boolean => {
  return getCompletedChapters().includes(chapterId);
};

/**
 * Reset all reading progress
 */
export const resetReadingProgress = () => {
  // Clear completed chapters
  document.cookie = "completedChapters=[];path=/;max-age=0";
  // Clear read letters
  document.cookie = "readLetters=[];path=/;max-age=0";
  // Force page reload to update UI
  window.location.reload();
};
