import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, IconButton } from "@mui/joy";
import { ColorPaletteProp } from "@mui/joy/styles";
import { useEffect, useState } from "react";

interface ToastProps {
  message: string;
  onClose: () => void;
  duration?: number;
  severity?: ColorPaletteProp;
}

const Toast = ({
  message,
  onClose,
  duration = 5000,
  severity = "success",
}: ToastProps) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(onClose, 300); // Wait for animation to complete
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <Box
      sx={{
        position: "fixed",
        top: { xs: "40%", md: "30%" },
        left: "50%",
        transform: visible ? "translate(-50%, -50%)" : "translate(-50%, -40%)",
        zIndex: 9999,
        opacity: visible ? 1 : 0,
        transition: "opacity 0.5s, transform 0.5s",
        width: { xs: "90%", sm: "70%", md: "50%" },
        maxWidth: "600px",
      }}
    >
      <Alert
        variant="soft"
        color={severity || "primary"}
        size="lg"
        sx={{
          boxShadow: "xl",
          borderRadius: "xl",
          border: "2px solid",
          borderColor: `${severity}.300`,
          fontSize: { xs: "1rem", sm: "1.2rem" },
          fontWeight: "medium",
          py: 3,
          px: 3,
          animation: visible ? "pulse 2s infinite" : "none",
          "@keyframes pulse": {
            "0%": {
              boxShadow: "0 0 0 0 rgba(255, 196, 0, 0.7)",
            },
            "70%": {
              boxShadow: "0 0 0 10px rgba(255, 196, 0, 0)",
            },
            "100%": {
              boxShadow: "0 0 0 0 rgba(255, 196, 0, 0)",
            },
          },
        }}
        endDecorator={
          <IconButton
            variant="plain"
            size="sm"
            color={severity || "primary"}
            onClick={() => {
              setVisible(false);
              setTimeout(onClose, 300);
            }}
          >
            <CloseIcon />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Box>
  );
};

export default Toast;
