import {
  Avatar,
  Box,
  Modal,
  ModalClose,
  Sheet,
  Typography,
  useColorScheme,
} from "@mui/joy";
import { useState } from "react";
import { characters } from "../../data/novellaData";

interface CharacterNameProps {
  id: string;
  children: React.ReactNode;
  avatarOnly?: boolean;
}

const CharacterName = ({
  id,
  children,
  avatarOnly = false,
}: CharacterNameProps) => {
  const [open, setOpen] = useState(false);
  // No longer need position state
  const { mode } = useColorScheme();
  const isDark = mode === "dark";

  const character = characters[id];

  if (!character) {
    return <>{children}</>;
  }

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      {avatarOnly ? (
        <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
          {children}
        </Box>
      ) : (
        <Typography
          component="span"
          onClick={handleClick}
          sx={{
            color: isDark ? "warning.300" : "warning.600",
            cursor: "pointer",
            fontWeight: "500",
            textDecoration: "none",
            position: "relative",
            "&:after": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "1px",
              bottom: 0,
              left: 0,
              backgroundColor: isDark ? "warning.300" : "warning.600",
              opacity: 0,
              transition: "opacity 0.2s",
            },
            "&:hover": {
              color: isDark ? "warning.200" : "warning.700",
              "&:after": {
                opacity: 0.5,
              },
            },
          }}
        >
          {children}
        </Typography>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 430,
            borderRadius: "lg",
            p: 4,
            boxShadow: "lg",
            position: "relative",
            border: "1px solid",
            borderColor: isDark ? "neutral.700" : "neutral.300",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "4px",
              bgcolor: isDark ? "warning.600" : "warning.500",
            },
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ position: "relative", mb: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 3,
                  mb: 4,
                }}
              >
                <Avatar
                  src={character.image}
                  alt={character.name}
                  sx={{
                    width: 100,
                    height: 100,
                    border: "3px solid",
                    borderColor: isDark ? "warning.600" : "warning.400",
                    boxShadow: "sm",
                  }}
                >
                  {character.name.charAt(0)}
                </Avatar>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    level="title-lg"
                    sx={{
                      fontFamily: "'Cinzel', serif",
                      fontWeight: "bold",
                      mb: 1.5,
                      lineHeight: 1.2,
                      display: "block",
                    }}
                  >
                    {character.name}
                  </Typography>

                  {character.title && (
                    <Typography
                      level="title-sm"
                      sx={{
                        color: isDark ? "warning.300" : "warning.800",
                        fontFamily: "'Cinzel', serif",
                        mb: 1,
                        display: "block",
                      }}
                    >
                      {character.title}
                    </Typography>
                  )}

                  {character.affiliation && (
                    <Typography
                      level="body-md"
                      sx={{
                        color: isDark ? "neutral.400" : "neutral.600",
                        display: "block",
                        fontStyle: "italic",
                      }}
                    >
                      {character.affiliation}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Typography
              level="body-md"
              sx={{
                fontFamily: "'EB Garamond', serif",
                lineHeight: 1.6,
                letterSpacing: "0.015em",
              }}
            >
              {character.description}
            </Typography>
          </Box>
        </Sheet>
      </Modal>
    </>
  );
};

export default CharacterName;
