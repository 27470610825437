import { Box } from "@mui/joy";
import { useEffect, useState } from "react";
import { chapters } from "../../data/novellaData";
import { Chapter } from "../../types/novellaTypes";
import {
  getReadLetters,
  markChapterAsCompleted,
} from "../../utils/cookieUtils";
import ChapterNavigation from "../NovellaReader/ChapterNavigation";
import CharacterGallery from "../NovellaReader/CharacterGallery";
import ReaderContent from "../NovellaReader/ReaderContent";
import ReaderHeader from "../NovellaReader/ReaderHeader";

const NovellaReader = () => {
  const [currentChapter, setCurrentChapter] = useState<Chapter>(chapters[0]);
  const [readLetters, setReadLetters] = useState<string[]>([]);

  // Load read letters from cookies on initial render
  useEffect(() => {
    setReadLetters(getReadLetters());

    // Set up an interval to periodically check for new read letters
    const intervalId = setInterval(() => {
      const currentReadLetters = getReadLetters();
      if (JSON.stringify(currentReadLetters) !== JSON.stringify(readLetters)) {
        setReadLetters(currentReadLetters);
      }
    }, 1000); // Check every second

    return () => clearInterval(intervalId);
  }, [readLetters]); // Empty dependency array to only run on mount

  const handleChapterChange = (chapter: Chapter) => {
    setCurrentChapter(chapter);
    // Mark current chapter as completed before moving to new chapter
    markChapterAsCompleted(currentChapter.id);
    // Scroll to top when changing chapter
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "background.body",
        color: "text.primary",
        transition: "background-color 0.3s, color 0.3s",
        px: { xs: 2, md: 4 },
        pb: 4,
      }}
    >
      <ReaderHeader />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 4,
          width: "100%",
          maxWidth: "1400px",
          mx: "auto",
          flex: 1,
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "25%" }, mb: { xs: 2, md: 0 } }}>
          <ChapterNavigation
            chapters={chapters}
            currentChapter={currentChapter}
            onChapterChange={handleChapterChange}
            readLetters={readLetters}
          />
        </Box>

        <Box sx={{ width: { xs: "100%", md: "75%" } }}>
          <ReaderContent
            chapter={currentChapter}
            onComplete={() => markChapterAsCompleted(currentChapter.id)}
          />
          <CharacterGallery />
        </Box>
      </Box>
    </Box>
  );
};

export default NovellaReader;
