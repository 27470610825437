import { Box, Divider, Typography } from "@mui/joy";
import Sheet from "@mui/joy/Sheet";
import CookieConsent from "react-cookie-consent";
import AboutUs from "./components/AboutUs";
import Footer from "./components/Footer";
import InteractiveMap from "./components/InteractiveMap";
import ModeToggle from "./components/ModeToggle";
import PreludeSection from "./components/PreludeSection";
import SapphireShorePOVs from "./components/SapphireShorePOVs";
import SapphireShoreRealms from "./components/SapphireShoreRealms";
import StainedHeader from "./components/StainedHeader";

// ContentWithHeader wraps content with the StainedHeader
const ContentWithHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Sheet
      variant="plain"
      color="neutral"
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <ModeToggle />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <StainedHeader />
        {children}
      </Box>
    </Sheet>
  );
};

// Main App Component - Just the home page
export default function App() {
  return (
    <>
      <ContentWithHeader>
        <Divider sx={{ m: 4 }} />
        <InteractiveMap />
        <Divider sx={{ m: 4 }} />
        <SapphireShoreRealms />
        <Divider sx={{ m: 4 }} />
        <SapphireShorePOVs />
        <Divider sx={{ m: 4 }} />
        <PreludeSection />
        <Divider sx={{ m: 4 }} />
        <AboutUs />
        <CookieConsent
          enableDeclineButton
          acceptOnScrollPercentage={5}
          onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling) {
              // Accepted by scrolling
            } else {
              // Accepted by clicking
            }
          }}
        >
          <Typography>
            This website uses cookies to enhance the user experience.
          </Typography>
        </CookieConsent>
      </ContentWithHeader>
      <Footer />
    </>
  );
}
