import {
  AspectRatio,
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
  useColorScheme,
} from "@mui/joy";
import { styled } from "@mui/system";
import { useState } from "react";
import { Character } from "../data/characterData";

interface FlipCardProps {
  character: Character;
}

const FlipCard = ({ character }: FlipCardProps) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const { mode } = useColorScheme();

  // Get color based on affiliation
  const getAffiliationColor = (affiliation: string) => {
    const affiliationLower = affiliation.toLowerCase();

    if (affiliationLower.includes("migora")) return "#FFD700"; // Golden
    if (affiliationLower.includes("hydaria")) return "#FF5252"; // Red
    if (affiliationLower.includes("rigata")) return "#4CAF50"; // Green
    if (affiliationLower.includes("vara")) return "#FF80AB"; // Pinkish

    return mode === "dark" ? "#333333" : "#EEEEEE"; // Default dark/light
  };

  // Get text color to ensure readability on the background
  const getTextColor = (affiliation: string) => {
    const affiliationLower = affiliation.toLowerCase();

    // Dark text for light backgrounds
    if (affiliationLower.includes("migora")) return "#000000"; // Black text on gold

    // Light text for dark backgrounds
    return "#FFFFFF"; // White text on other colors
  };

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const CardInner = styled("div")(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: "100%",
    textAlign: "center",
    transition: "transform 0.8s cubic-bezier(0.4, 0, 0.2, 1)",
    transformStyle: "preserve-3d",
    transform: `${isFlipped ? "rotateY(180deg)" : "rotateY(0deg)"}`,
  }));

  const CardFrontBack = styled("div")(({ theme }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    borderRadius: "8px",
  }));

  const CardBack = styled(CardFrontBack)(({ theme }) => ({
    transform: "rotateY(180deg)",
  }));

  return (
    <Card
      variant="soft"
      sx={{
        width: "100%",
        minWidth: "320px",
        height: 520,
        cursor: "pointer",
        backgroundImage: "url(/assets/paper-texture.png)",
        backgroundBlendMode: "overlay",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.15)",
        "&:hover": {
          boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
          transform: "translateY(-3px)",
          transition: "transform 0.3s ease, box-shadow 0.3s ease",
        },
        overflow: "hidden",
        m: 1,
      }}
      onClick={handleFlip}
    >
      <CardInner>
        <CardFrontBack>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%",
              p: 3,
            }}
          >
            {/* Top ornamental border */}
            <Box
              sx={{
                width: "80%",
                height: "16px",
                background:
                  mode === "dark"
                    ? "linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.15) 15%, rgba(255, 255, 255, 0.15) 85%, transparent 100%)"
                    : "linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.15) 85%, transparent 100%)",
                opacity: 0.6,
                marginBottom: 1,
              }}
            />

            <Typography
              level="h4"
              sx={{
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-8px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "40%",
                  height: "1px",
                  background:
                    mode === "dark"
                      ? "rgba(255, 255, 255, 0.3)"
                      : "rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              {character.name}
            </Typography>

            <AspectRatio
              ratio="1"
              sx={{
                width: 140,
                my: 2,
                borderRadius: "50%",
                border:
                  mode === "dark"
                    ? "3px solid rgba(255, 255, 255, 0.2)"
                    : "3px solid rgba(0, 0, 0, 0.2)",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                padding: "3px",
                overflow: "hidden",
              }}
            >
              <Avatar
                alt={character.name}
                src={character.imageSrc}
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </AspectRatio>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                level="body-lg"
                sx={{
                  fontStyle: "italic",
                  opacity: 0.85,
                }}
              >
                {character.rank}
              </Typography>

              <Divider
                sx={{
                  width: "40%",
                  opacity: 0.7,
                  my: 1,
                  mx: "auto",
                }}
              />

              <Chip
                sx={{
                  bgcolor: getAffiliationColor(character.affiliation),
                  color: getTextColor(character.affiliation),
                  fontWeight: "medium",
                  marginBottom: 2,
                }}
                size="md"
              >
                {character.affiliation}
              </Chip>

              <Typography
                level="body-sm"
                sx={{
                  fontStyle: "italic",
                  opacity: 0.7,
                }}
              >
                Tap to reveal biography
              </Typography>
            </Box>

            {/* Bottom ornamental border */}
            <Box
              sx={{
                width: "80%",
                height: "16px",
                background:
                  mode === "dark"
                    ? "linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.15) 15%, rgba(255, 255, 255, 0.15) 85%, transparent 100%)"
                    : "linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.15) 85%, transparent 100%)",
                opacity: 0.6,
                marginTop: 1,
              }}
            />
          </CardContent>
        </CardFrontBack>

        <CardBack>
          <CardContent
            sx={{
              height: "100%",
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Top ornamental border */}
            <Box
              sx={{
                width: "80%",
                height: "16px",
                background:
                  mode === "dark"
                    ? "linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.15) 15%, rgba(255, 255, 255, 0.15) 85%, transparent 100%)"
                    : "linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.15) 85%, transparent 100%)",
                opacity: 0.6,
                marginBottom: 1,
              }}
            />

            <Typography
              level="h4"
              sx={{
                mb: 2,
                position: "relative",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-8px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "40%",
                  height: "1px",
                  background:
                    mode === "dark"
                      ? "rgba(255, 255, 255, 0.3)"
                      : "rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              {character.name}
            </Typography>

            <Box
              sx={{
                flex: 1,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                level="body-md"
                sx={{
                  lineHeight: 1.6,
                  textAlign: "justify",
                  fontStyle: "italic",
                  p: 1,
                }}
              >
                {character.biography}
              </Typography>
            </Box>

            <Typography
              level="body-sm"
              sx={{
                fontStyle: "italic",
                opacity: 0.7,
                mt: 1,
              }}
            >
              Tap to return
            </Typography>

            {/* Bottom ornamental border */}
            <Box
              sx={{
                width: "80%",
                height: "16px",
                background:
                  mode === "dark"
                    ? "linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.15) 15%, rgba(255, 255, 255, 0.15) 85%, transparent 100%)"
                    : "linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.15) 15%, rgba(0, 0, 0, 0.15) 85%, transparent 100%)",
                opacity: 0.6,
                marginTop: 1,
              }}
            />
          </CardContent>
        </CardBack>
      </CardInner>
    </Card>
  );
};

export default FlipCard;
