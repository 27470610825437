export interface Character {
  id: string;
  name: string;
  rank: string;
  affiliation: string;
  biography: string;
  imageSrc: string;
}

export const povCharacters: Character[] = [
  {
    id: "silea",
    name: "Silea",
    rank: "Golden Weaver",
    affiliation: "Migora",
    biography:
      "Washed ashore following a terrible storm, Silea awakens to find herself with no recollection of her past. Blessed with villagers that gave her shelter, she would soon discover her curious ability to master crafts that often took years in the span of weeks or months. An ability that would see her deprived of her much beloved hearth and home as she is thrust into the dealings of those that govern the world.",
    imageSrc: "/assets/characters/TSS/silea.png",
  },
  {
    id: "mithra",
    name: "Mithra",
    rank: "Lady of War",
    affiliation: "Migora",
    biography:
      "Having lost her husband, High Lord of War Saryov, to a fake peace negotiation with the Hydarians at Novgara. Lady Mithra had embarked on a self ruinous quest to destroy the Hydarians, but the wisdom of those close to her to temper her blade and gain support in order to deal a more decisive blow to Hydaria led up to one of the most iconic battles of the Migoran Kingdom's era. The Battle of Ash. It was then that Mithra built a fort city, trained an elite fighting force, and rose to prominence as Migora's most capable Lady of War.",
    imageSrc: "/assets/characters/TSS/mithra.png",
  },
  {
    id: "lysiac",
    name: "Lysiac",
    rank: "Battlemaster",
    affiliation: "Hydaria",
    biography:
      "Coming off a recent promotion to Battlemaster, the champion of Hida's Dance has finally been given complete control over one of Hydaria's armies. Fuelled by ambition and the desire to prove his merit to his father, High Warmaster Ragos, Lysiac has involved himself heavily in war councils, as well as training fresh recruits personally. But his greatest of tasks certainly lay ahead of him, as the world moves to collide once more.",
    imageSrc: "/assets/characters/TSS/lysiac.png",
  },
  {
    id: "arog",
    name: "Arog",
    rank: "Former Royal Guard",
    affiliation: "Rigata",
    biography:
      "Living under the yoke of Hydarian oppression for much of his life, Arog's will to rebel had been sapped by the Rigatan's latest setback, as well as mired by strange dreams that have long since plagued him. Often calling them visions, they have intensified of late, leaving him out of commission for days on end. While his sense of justice and ideals remain firm, his mind now wanders perhaps to a purer purpose.",
    imageSrc: "/assets/characters/TSS/arog.png",
  },
  {
    id: "gormog",
    name: "Gormog",
    rank: "Giant",
    affiliation: "Vara",
    biography:
      "Many claim that the village of Giants nestled between Veran lands is but a myth, yet as living breathing proof, Gormog would beg to differ. What old oaths of fealty his people swore to High King Alon of Migora have largely been ignored, leaving them all to fend for themselves against the Archons of the south.",
    imageSrc: "/assets/characters/TSS/gormog.png",
  },
  {
    id: "farkion",
    name: "Farkion",
    rank: "Councillor",
    affiliation: "Migora",
    biography:
      "Councillor in name only, Farkion has become a shell of the man he once was. The once mighty man has succumbed slowly to his addictions, often neglecting the one thing he values as a result, his daughter Frula. Grim and ill at ease as he now is, perhaps his aging bones and waning strength have enough in them for one last act of courage.",
    imageSrc: "/assets/characters/TSS/farkion.png",
  },
  {
    id: "therak",
    name: "Therak",
    rank: "Councillor",
    affiliation: "Migora",
    biography:
      "Risen from complete obscurity, Therak has attained the highest title one of his birth might in Migora. Proving time and time again his capability and sharp mind in governing trade, influencing policies, and even conferring on matters of war. But even he is not always capable of stopping High King Alon's War Council from its latest blunders.",
    imageSrc: "/assets/characters/TSS/therak.png",
  },
  {
    id: "faren",
    name: "Faren",
    rank: "Healer",
    affiliation: "The Riverlands",
    biography:
      "With a background and heritage that sees her despised in her small Village of Luxues, Faren has taken on the mantra of a healer, trying to undo whatever wrongs were perceived of her or her bloodline. Though to little effect, attracting only the friendship of a brilliant alchemist with a heart of paper, Sadya. Living perilously close to the Foreboding forest, it was only a matter of time before their herb gathered took them a step too deep.",
    imageSrc: "/assets/characters/TSS/faren.png",
  },
  {
    id: "phatasma",
    name: "Phatasma",
    rank: "The Queen of Scoundrels",
    affiliation: "The Gaudy Coast",
    biography:
      "Ruthless, cunning, and eerily captivating. Some say the Queen of Scoundrels has mellowed with age, that the murderer of High King Alon's daughter princess has softened. Those very same people would often line the bottom of her ship's hull in a token of gratitude that they would keep her on edge. Though there is no denying it, her maneuvering of late have been rather peculiar.",
    imageSrc: "/assets/characters/TSS/phatasma.png",
  },
  {
    id: "vareyan",
    name: "Vareyan",
    rank: "Wandering Scholar",
    affiliation: "Osgara",
    biography:
      "A lone wandering scholar with a tongue as sharp as his mind, Vareyan had set out on a self-imposed quest to uncover certain truths of the world that he deemed were too important to hide no matter how well the force that hid them wished for them to remain unnoticed.",
    imageSrc: "/assets/characters/TSS/vareyan.png",
  },
  {
    id: "iliac",
    name: "Iliac",
    rank: "???",
    affiliation: "Osgara",
    biography:
      "A myth to be sure, the stories surrounding a white robed figure in the forboding forest causing all manner of supernatural events is surely just meant to scare people from the forest. And whatever secret it may hide.",
    imageSrc: "/assets/characters/TSS/iliac.png",
  },
  {
    id: "abmer",
    name: "Abmer",
    rank: "Recruit",
    affiliation: "Hydaria",
    biography:
      "Victim of High Lord of War's latest great mustering. Abmer finds himself plucked from his serene lifestyle, and thrust into battles and wars that he had very little to do with.",
    imageSrc: "/assets/characters/TSS/abmer.png",
  },
  {
    id: "caramansia",
    name: "Caramansia",
    rank: "Bard in Training",
    affiliation: "Hydaria",
    biography:
      "Faithful companion to the two heirs of the Hydarian throne, Cara hopes to follow in her mother's footsteps as a captivating balladist.",
    imageSrc: "/assets/characters/TSS/caramansia.png",
  },
  {
    id: "crimosa",
    name: "Crimosa",
    rank: "Renowned Soprano",
    affiliation: "Hydaria",
    biography:
      "Often described as the epitome of Hydarian class, in spite of her 'wrong' hair color, Crimosa has indeed captivated the hearts of many with her voice and song. Though many do not know of her true purpose.",
    imageSrc: "/assets/characters/TSS/crimosa.png",
  },
];
