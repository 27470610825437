// Import Firebase functions and types
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Firebase configuration for Timeless Aria
const firebaseConfig = {
  apiKey: "AIzaSyBeLwZpYIPjf7MsHoxNyJuUzAnrZBjWhag",
  authDomain: "timeless-aria.firebaseapp.com",
  databaseURL:
    "https://timeless-aria-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "timeless-aria",
  storageBucket: "timeless-aria.appspot.com",
  messagingSenderId: "83219935199",
  appId: "1:83219935199:web:a77ed5a46df4470cb69e6a",
  measurementId: "G-W1DVYEFLTK",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const database = getDatabase(app);

// Export a function to get the database to make imports cleaner
export const getFirebaseDatabase = () => database;
