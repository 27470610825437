import { Box, Grid, Typography, useColorScheme } from "@mui/joy";
import { useMediaQuery, useTheme } from "@mui/material";
import { povCharacters } from "../data/characterData";
import FlipCard from "./FlipCard";

export default function SapphireShorePOVs() {
  const theme = useTheme();
  const { mode } = useColorScheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          maxWidth: "800px",
          margin: "0 auto 5rem",
          position: "relative",
          textAlign: "center",
        }}
      >
        <Typography
          level={!md ? "h2" : "h3"}
          sx={{
            marginBottom: "0.5rem",
            position: "relative",
            display: "inline-block",
            "&::before, &::after": {
              content: '""',
              position: "absolute",
              width: "30px",
              height: "1px",
              background:
                mode === "dark"
                  ? "rgba(255, 255, 255, 0.3)"
                  : "rgba(0, 0, 0, 0.3)",
              top: "50%",
              opacity: 0.8,
            },
            "&::before": {
              left: "-50px",
            },
            "&::after": {
              right: "-50px",
            },
          }}
        >
          POV Characters
        </Typography>
        <Typography
          level="title-md"
          sx={{
            fontStyle: "italic",
            opacity: 0.85,
            mt: 1,
          }}
        >
          of The Sapphire Shore
        </Typography>
      </Box>

      <Grid
        container
        spacing={3}
        sx={{
          px: { xs: 2, sm: 3, md: 6, lg: 8 },
          maxWidth: "1600px",
          margin: "0 auto",
          justifyContent: { xs: "center", sm: "flex-start" },
        }}
      >
        {povCharacters.map((character) => (
          <Grid key={character.id} xs={12} md={6} lg={4} xl={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <FlipCard character={character} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
