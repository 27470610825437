import { Box, Divider, Sheet, Typography, useColorScheme } from "@mui/joy";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { characters, letters, locations } from "../../data/novellaData";
import { Chapter } from "../../types/novellaTypes";
import {
  chapterToLetterMapping,
  getReadLetters,
  isChapterCompleted,
  markChapterAsCompleted,
  markLetterAsRead,
} from "../../utils/cookieUtils";
import CharacterName from "./CharacterName";
import LetterDisplay from "./LetterDisplay";
import LocationName from "./LocationName";
import Toast from "./Toast";

interface ReaderContentProps {
  chapter: Chapter;
  onComplete?: () => void;
}

const ReaderContent = ({ chapter, onComplete }: ReaderContentProps) => {
  const { mode } = useColorScheme();
  const isDark = mode === "dark";
  const contentRef = useRef<HTMLDivElement>(null);
  const [processedContent, setProcessedContent] =
    useState<React.ReactNode>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isChapterComplete, setIsChapterComplete] = useState<boolean>(false);
  const [chapterEnded, setChapterEnded] = useState<boolean>(false);

  // Check if chapter is already completed
  useEffect(() => {
    const completed = isChapterCompleted(chapter.id);
    setIsChapterComplete(completed);

    // Reset state when chapter changes
    setChapterEnded(false);
    setShowToast(false);
  }, [chapter.id]);

  // Show letter directly if chapter is already completed
  useEffect(() => {
    if (isChapterComplete && chapter.containsLetter && chapter.letterId) {
    }
  }, [isChapterComplete, chapter.containsLetter, chapter.letterId]);

  // When the user scrolls to 85% of the page, consider the chapter completed
  // and unlock corresponding letter if available
  useEffect(() => {
    const handleScroll = () => {
      if (chapterEnded || isChapterComplete) return;

      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      const scrollPosition = scrollTop + clientHeight;
      const scrollThreshold = scrollHeight * 0.85; // 85% scrolled

      if (scrollPosition >= scrollThreshold) {
        console.log("Chapter completed by scrolling:", chapter.id);

        // Mark this chapter as completed
        markChapterAsCompleted(chapter.id);
        if (onComplete) onComplete();

        setChapterEnded(true);
        setIsChapterComplete(true);

        // Check if this chapter has a corresponding letter to unlock
        const correspondingLetterId = chapterToLetterMapping[chapter.id];
        if (correspondingLetterId) {
          console.log(
            `Unlocking letter: ${correspondingLetterId} from chapter ${chapter.id}`
          );

          // Mark the letter as read
          markLetterAsRead(correspondingLetterId);

          // Show the letter with a slight delay
          setTimeout(() => {
            setShowToast(true);

            console.log("Letter unlocked and toast shown");
          }, 300);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [chapter.id, chapterEnded, isChapterComplete, onComplete]);

  // Process the content to add interactive elements
  // Scroll to the specific letter if a hash is present in the URL
  useEffect(() => {
    if (chapter.containsAllLetters && window.location.hash) {
      const letterId = window.location.hash.substring(1); // Remove the # character
      const letterElement = document.getElementById(letterId);

      if (letterElement) {
        // Add a small delay to ensure the element is rendered
        setTimeout(() => {
          // If we're already on the letters page, scroll directly without smooth behavior
          // This prevents scrolling to the top first and then down to the letter
          const scrollBehavior = chapter.id === "letters" ? "auto" : "smooth";
          letterElement.scrollIntoView({
            behavior: scrollBehavior,
            block: "start",
          });
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter.containsAllLetters, window.location.hash, chapter.id]);

  useEffect(() => {
    const processText = (text: string) => {
      if (!text) return null;

      // If this chapter contains a letter, let's remove the letter content from the chapter content
      // since it will be displayed by the LetterDisplay component
      let processedText = text;
      if (chapter.containsLetter && chapter.letterId) {
        // Letter chapters have standardized format with "Letter X" at the top
        // and the actual letter content below
        const letterHeaderMatch = processedText.match(/^Letter \d+\s*\n\n/);
        if (letterHeaderMatch) {
          // Remove everything after the letter header
          processedText = letterHeaderMatch[0];
        }
      }

      // Create maps of character and location names for quick lookup
      const characterNameMap: Record<string, string> = {};
      const locationMap: Record<string, string> = {};

      // Preprocess characters and locations for faster lookups
      Object.entries(characters).forEach(([id, character]) => {
        // Add the main name
        characterNameMap[character.name.toLowerCase()] = id;

        // Add all alternate names
        character.alternateNames.forEach((altName) => {
          if (altName) {
            characterNameMap[altName.toLowerCase()] = id;
          }
        });
      });

      Object.entries(locations).forEach(([id, location]) => {
        locationMap[location.name.toLowerCase()] = id;
      });

      // Process text in a more efficient way - split by paragraphs first
      // This way we only process visible text, not the entire document at once
      const paragraphs = processedText.split("\n\n");
      return paragraphs
        .map((paragraph, paragraphIndex) => {
          // Skip empty paragraphs
          if (paragraph.trim() === "") return null;

          // Special paragraph formatting first
          if (paragraph.startsWith("***")) {
            return (
              <Box
                key={`divider-${paragraphIndex}`}
                sx={{
                  textAlign: "center",
                  my: 4,
                  color: isDark ? "neutral.400" : "neutral.600",
                }}
              >
                <Typography level="body-md">***</Typography>
              </Box>
            );
          } else if (paragraph.match(/^[A-Z\s]+$/)) {
            return (
              <Typography
                key={`header-${paragraphIndex}`}
                level="title-md"
                sx={{
                  fontFamily: "'Cinzel', serif",
                  textAlign: "center",
                  mt: 4,
                  mb: 2,
                }}
              >
                {paragraph}
              </Typography>
            );
          } else if (
            // Handle poem formats:
            // 1. The poem in SPARK chapter spans from paragraphs 2-11
            // 2. Regular quoted paragraphs
            // 3. The poem in FIRE chapter (has specific indentation and structure)
            (paragraph.startsWith('"') && paragraph.endsWith('"')) ||
            (paragraph.startsWith('"') && paragraph.includes("\n")) ||
            (paragraph.startsWith('"') &&
              paragraphIndex >= 2 &&
              paragraphIndex <= 11) ||
            (chapter.id === "fire" &&
              (paragraph.trim().startsWith("“Their plot, we uncovered,") ||
                paragraph.trim().startsWith("led by the three.") ||
                paragraph.trim().startsWith("Their treachery, discovered,") ||
                paragraph.trim().startsWith("as they attempted to flee.") ||
                paragraph
                  .trim()
                  .startsWith("To a warehouse they were chased,") ||
                paragraph.trim().startsWith("where they raised barricades.") ||
                paragraph.trim().startsWith("By fire we laid waste,") ||
                paragraph.trim().startsWith("to their incursion of blades.") ||
                paragraph.trim().startsWith("Charred corpses we offer,") ||
                paragraph.trim().startsWith("nothing but burnt lies.") ||
                paragraph
                  .trim()
                  .startsWith("For these bodies had no honour,") ||
                paragraph.trim().startsWith("return to them their spies.”"))) ||
            (chapter.id === "spark" &&
              (paragraph
                .trim()
                .startsWith("She asked near smoke with eye bearing tear") ||
                paragraph.trim().startsWith("I rest only when my") ||
                paragraph.trim().startsWith("He said as the flame") ||
                paragraph
                  .trim()
                  .startsWith("I fear the screams of our dying youth") ||
                paragraph.trim().startsWith("Unease as the fire cackled") ||
                paragraph.trim().startsWith("I fight for our king") ||
                paragraph
                  .trim()
                  .startsWith("He doused the fire and turned to dash") ||
                paragraph
                  .trim()
                  .startsWith("O road be steady and carry his feet") ||
                paragraph
                  .trim()
                  .startsWith("She sighed in a haze as she watched") ||
                paragraph
                  .trim()
                  .startsWith("For his was to end their greatest of wars")))
          ) {
            // Look for various poem formats across chapters
            return (
              <Box
                key={`poem-${paragraphIndex}`}
                sx={{ my: 3, mx: { xs: 2, sm: 6 } }}
              >
                <Typography
                  level="body-md"
                  sx={{
                    fontFamily: "'EB Garamond', serif",
                    fontStyle: "italic",
                    whiteSpace: "pre-line",
                    textAlign: "center",
                    lineHeight: 1.8,
                  }}
                >
                  {paragraph}
                </Typography>
              </Box>
            );
          }

          // For regular paragraphs, process the text to highlight names
          // Split text by words and spaces to preserve formatting
          const words = paragraph.split(/(\s+)/);
          const content = words.map((word, wordIndex) => {
            // Skip spaces
            if (word.trim() === "") {
              // Even spaces need keys when in an array
              return (
                <Fragment key={`space-${paragraphIndex}-${wordIndex}`}>
                  {word}
                </Fragment>
              );
            }

            // Create a lowercase version of the word for case-insensitive matching
            const lowerWord = word.toLowerCase();

            // Search for character names
            for (const [characterNameLower, characterId] of Object.entries(
              characterNameMap
            )) {
              if (characterNameLower.length < 3) continue; // Skip very short names to avoid false positives

              const index = lowerWord.indexOf(characterNameLower);
              if (index !== -1) {
                const nameLength = characterNameLower.length;

                // If it's at the beginning or end of a word, or it's a whole word
                if (
                  index === 0 ||
                  index + nameLength === word.length ||
                  (index > 0 && !/[a-zA-Z]/.test(word[index - 1])) ||
                  (index + nameLength < word.length &&
                    !/[a-zA-Z]/.test(word[index + nameLength]))
                ) {
                  const prefix = word.substring(0, index);
                  const match = word.substring(index, index + nameLength);
                  const suffix = word.substring(index + nameLength);

                  return (
                    <Fragment key={`char-part-${paragraphIndex}-${wordIndex}`}>
                      {prefix}
                      <CharacterName id={characterId}>{match}</CharacterName>
                      {suffix}
                    </Fragment>
                  );
                }
              }
            }

            // Search for location names within the word
            for (const [locationNameLower, locationId] of Object.entries(
              locationMap
            )) {
              if (locationNameLower.length < 3) continue; // Skip very short names

              const index = lowerWord.indexOf(locationNameLower);
              if (index !== -1) {
                const nameLength = locationNameLower.length;

                // If it's at the beginning or end of a word, or it's a whole word
                if (
                  index === 0 ||
                  index + nameLength === word.length ||
                  (index > 0 && !/[a-zA-Z]/.test(word[index - 1])) ||
                  (index + nameLength < word.length &&
                    !/[a-zA-Z]/.test(word[index + nameLength]))
                ) {
                  const prefix = word.substring(0, index);
                  const match = word.substring(index, index + nameLength);
                  const suffix = word.substring(index + nameLength);

                  return (
                    <Fragment key={`loc-part-${paragraphIndex}-${wordIndex}`}>
                      {prefix}
                      <LocationName id={locationId}>{match}</LocationName>
                      {suffix}
                    </Fragment>
                  );
                }
              }
            }

            // If no matches found, return the original word with a key
            return (
              <Fragment key={`word-${paragraphIndex}-${wordIndex}`}>
                {word}
              </Fragment>
            );
          });

          // Return the paragraph with highlighted names
          return (
            <Typography
              key={`paragraph-${paragraphIndex}`}
              level="body-md"
              sx={{
                fontFamily: "'EB Garamond', serif",
                mb: 2,
                textIndent: "2em",
                lineHeight: "1.7",
              }}
            >
              {content}
            </Typography>
          );
        })
        .filter(Boolean); // Remove null entries
    };

    setProcessedContent(processText(chapter.content));
  }, [chapter, isDark]);

  return (
    <Sheet
      variant="plain"
      sx={{
        p: { xs: 3, md: 5 },
        borderRadius: "lg",
        bgcolor: isDark ? "neutral.900" : "background.surface",
        maxWidth: "900px",
        mx: "auto",
        boxShadow: "md",
        border: "1px solid",
        borderColor: isDark ? "neutral.800" : "neutral.100",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Typography
        level="h2"
        component="h1"
        sx={{
          fontFamily: "'Cinzel', serif",
          textAlign: "center",
          mb: 5,
          borderBottom: "1px solid",
          borderColor: "divider",
          pb: 2,
          fontSize: { xs: "1.8rem", md: "2.2rem" },
          letterSpacing: "0.02em",
          fontWeight: "bold",
        }}
      >
        {chapter.title}
      </Typography>

      {/* Letter display in letter chapters */}
      {chapter.containsLetter && chapter.letterId && (
        <div id="letter-section">
          <LetterDisplay letterId={chapter.letterId} />
        </div>
      )}

      {/* Display all unlocked letters in Letters section */}
      {chapter.containsAllLetters && (
        <div id="letters-section">
          <Typography
            level="body-lg"
            sx={{
              textAlign: "center",
              mb: 4,
              fontFamily: "'EB Garamond', serif",
              color: isDark ? "neutral.300" : "neutral.700",
              fontStyle: "italic",
            }}
          >
            These are the letters you've discovered throughout the story.
          </Typography>

          {getReadLetters().length === 0 ? (
            <Box sx={{ textAlign: "center", my: 8 }}>
              <Typography
                level="body-lg"
                sx={{
                  fontStyle: "italic",
                  color: isDark ? "neutral.400" : "neutral.600",
                }}
              >
                No letters discovered yet. Continue reading to unlock them.
              </Typography>
            </Box>
          ) : (
            getReadLetters().map((letterId, index) => (
              <React.Fragment key={letterId}>
                {index > 0 && <Divider sx={{ my: 5 }} />}
                <div id={letterId}>
                  <LetterDisplay letterId={letterId} />
                </div>
              </React.Fragment>
            ))
          )}
        </div>
      )}

      {/* Toast notification for when a letter is unlocked */}
      {showToast && chapterToLetterMapping[chapter.id] && (
        <Toast
          message={`Congratulations! You've reached the end of ${
            chapter.title
          } and unlocked a letter: "${
            letters[chapterToLetterMapping[chapter.id]]?.title
          }". It's now available in the Letters section.`}
          severity="warning"
          duration={10000}
          onClose={() => setShowToast(false)}
        />
      )}

      <Box
        ref={contentRef}
        sx={{
          fontSize: { xs: "17px", md: "19px" },
          px: { xs: 0, sm: 2, md: 6 },
          "& .MuiTypography-root": {
            mb: 3,
            textIndent: "2em",
            lineHeight: "1.8",
            letterSpacing: "0.01em",
          },
        }}
      >
        {processedContent}
      </Box>
    </Sheet>
  );
};

export default ReaderContent;
