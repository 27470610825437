import { Avatar, Box, Tooltip, Typography, useColorScheme } from "@mui/joy";
import { characters } from "../../data/novellaData";
import CharacterName from "./CharacterName";

const CharacterGallery = () => {
  const { mode } = useColorScheme();
  const isDark = mode === "dark";

  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderColor: isDark ? "neutral.700" : "neutral.200",
        pt: 4,
        mt: 4,
      }}
    >
      <Typography
        level="title-lg"
        sx={{
          fontFamily: "'Cinzel', serif",
          mb: 3,
          textAlign: "center",
          fontSize: "1.5rem",
          color: isDark ? "neutral.200" : "neutral.800",
        }}
      >
        Characters
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {Object.entries(characters).map(([id, character]) => (
          <Box
            key={id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100px",
              mb: 2,
            }}
          >
            <CharacterName id={id} avatarOnly={true}>
              <Tooltip
                title={`${character.name}${
                  character.title ? ` - ${character.title}` : ""
                }`}
                variant="soft"
              >
                <Avatar
                  src={character.image}
                  alt={character.name}
                  size="lg"
                  variant="outlined"
                  color={isDark ? "neutral" : "primary"}
                  sx={{
                    width: 70,
                    height: 70,
                    cursor: "pointer",
                    border: "2px solid",
                    borderColor: isDark ? "neutral.600" : "primary.200",
                    transition: "transform 0.2s, border-color 0.2s",
                    bgcolor: isDark ? "neutral.800" : "primary.50",
                    "&:hover": {
                      transform: "scale(1.1)",
                      borderColor: isDark ? "primary.400" : "primary.400",
                    },
                    "& .MuiAvatar-fallback": {
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                    },
                  }}
                />
              </Tooltip>
            </CharacterName>
            <Typography
              level="body-sm"
              sx={{
                mt: 1,
                textAlign: "center",
                fontSize: "0.8rem",
                fontWeight: "medium",
                fontFamily: "'Cinzel', serif",
                color: isDark ? "neutral.300" : "neutral.700",
              }}
            >
              {character.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CharacterGallery;
