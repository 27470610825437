import { Box } from "@mui/joy";
import ModeToggle from "../ModeToggle";
import NovellaReader from "./NovellaReader";

// Simple wrapper for NovellaReader
const NovellaReaderWrapper = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        position: "relative",
        bgcolor: "background.body",
      }}
    >
      {/* Mode toggle in the corner */}
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 10,
        }}
      >
        <ModeToggle />
      </Box>

      {/* The actual reader component */}
      <NovellaReader />
    </Box>
  );
};

export default NovellaReaderWrapper;
